var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "title": "Preview Pemilihan Stok",
      "id": "form-preview-stock",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    staticClass: "w-full",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.stocks,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(++index) + " ")];
      }
    }, {
      key: "cell(is_error)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.is_error ? _c('i', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(item.message))]) : _c('strong', {
          staticClass: "text-success"
        }, [_vm._v("Stok sudah dipilih")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }