var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "lg",
      "id": "checked-barang-modal"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "danger",
            "disabled": _vm.checkedBarang.length < 1
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.removeAll($event);
            }
          }
        }, [_vm._v("Hapus")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.stocks
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function callback($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.checkedBarang,
            callback: function callback($$v) {
              _vm.checkedBarang = $$v;
            },
            expression: "checkedBarang"
          }
        })];
      }
    }, {
      key: "cell(kategori)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.kategori ? item.kategori.kategori : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(stocks)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getTotal(item.stocks)) + " ")];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }