<template>
  <main>
    <b-modal
      v-if="isSales || isSPV"
      v-model="showModalBarang"
      id="modal-barang"
      ok-title="Tutup"
      ok-variant="secondary"
      no-close-on-backdrop
      ok-only
      centered
      size="xl"
      title="Cari Barang Untuk Diajukan"
    >
      <!-- Search Barang -->
      <b-row class="mb-2 justify-content-end">
        <b-col sm="12" md="6">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
            >
              <b-input-group size="sm">
                  <b-form-input
                  id="filterInput"
                  @keyup.enter="filterBarangMobil"
                  type="search"
                  placeholder="Type to Search"
                  />
                  <b-input-group-append>
                  <b-button> Clear </b-button>
                  </b-input-group-append>
              </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- / -->
      <!-- List Barang terpilih -->
      <b-button @click.prevent="viewCheckedBarang" variant="success" class="d-flex align-items-center mb-2" :disabled="checkedBarang.length < 1">
        <feather-icon icon="ShoppingBagIcon" class="mr-1" size="20" /> 
        <span>{{ checkedBarang.length }} barang dipilih.</span>
      </b-button>
      <!-- / -->
      <!-- List Barang -->
      <b-table
        striped
        small
        hover
        responsive
        :per-page="tableBarang.perPage"
        :current-page="tableBarang.currentPage"
        :fields="barangFields"
        :items="barangs"
        :filter="tableBarang.filter"
        :filter-included-fields="tableBarang.filterOn"
        @filtered="onBarangFiltered"
      >
        <template #head(checkbox)>
          <b-form-checkbox v-model="allChecked" />
        </template>
        <template #cell(checkbox)="{item}">
          <b-form-checkbox v-model="checkedBarang" :value="item" />
        </template>
        <template #cell(satuan)="{item, index}">
          <span v-if="item.konversi && item.konversi.length < 1">{{ item.satuan ? item.satuan.satuan : '-' }}</span> 
          <b-form-select @change="onSatuanKonversiSelected(index)" v-model="item.selectedKonversi" v-else :options="item.konversi"></b-form-select>
        </template>
        <template #cell(stocks)="{item}">
          {{ item.stocks && item.stocks.length > 0 ? item.stocks.reduce((total, stock) => stock.stok > 0 ? total += stock.stok : total += 0, 0) : 0 }} {{ item.satuan ? item.satuan.satuan : '-' }}
        </template>
        <template #cell(qty_request)="{item, index}">
          <b-form-input type="number" v-model="item.qty_request" @keyup="onSatuanKonversiSelected(index)"></b-form-input>
        </template>
        <template #cell(converted)="{item}">
          <!-- <b-form-input  disabled :value="`${item.converted} /${item.satuan ? item.satuan.satuan.toLowerCase() : ''}`"></b-form-input> -->
          <strong v-if="item.konversi && item.konversi.length > 0">
            {{ item.converted }} <span v-if="item.converted > 0">{{ item.satuan ? item.satuan.satuan.toLowerCase() : '' }}</span>
          </strong>
          <i v-else class="text-danger">
            <small>Satuan Konversi belum diinput</small>
          </i>
        </template>
      </b-table>
      <!-- / -->
      <!-- Pagination -->
      <b-row>
          <b-col cols="12">
              <b-pagination
                  v-model="tableBarang.currentPage"
                  :total-rows="barangs.length"
                  :per-page="tableBarang.perPage"
                  align="center"
                  size="sm"
                  class="my-0"
              />
          </b-col>
      </b-row>
      <!-- / -->
      <!-- footer -->
      <template #modal-footer>
        <section class="d-flex w-100 justify-content-end">
          
          <b-button @click.prevent="endChooseBarang" variant="info">
            Selesai
          </b-button>
        </section>
      </template>
    </b-modal>
    <b-overlay :show="loading">
      <validation-observer ref="form">

        <!-- two card -->
        <b-row>
          <!-- List barang di penerimaan -->
          <b-col sm="12" md="12">
            <div v-if="isAdminGudang && !isSales && rb.status >= 3" class="d-flex justify-content-end mb-2">
              <b-button variant="outline-danger" @click.prevent="printSuratJalan">
                <feather-icon icon="PrinterIcon"></feather-icon>
                Cetak Surat Jalan</b-button>
            </div>
            <b-card>
              <b-row>
                    <b-col md="3" sm="12" class="mb-2">
                        <p><strong>Kode Ajuan Barang</strong></p>
                        <p>
                          <strong>{{rb.no}}</strong>
                        </p>
                    </b-col>
                    <b-col md="3" sm="12" class="mb-2">
                        <p><strong>Tanggal Pengajuan</strong></p>
                        <p>{{ humanDateTime(rb.tanggal_waktu) }}</p>
                    </b-col>
                    <b-col md="3" sm="12" class="mb-2">
                        <p><strong>Nama Sales</strong></p>
                        <p>{{ rb.sales ? rb.sales.nama_lengkap : '-' }}</p>
                    </b-col>
                    <b-col md="3" sm="12" class="mb-2">
                        <p><strong>Keterangan</strong></p>
                        <p>{{rb.keterangan ? rb.keterangan : '-'}}</p>
                    </b-col>
                    <b-col cols="12" class="mb-2">
                        <p><strong>Rute Ajuan Barang</strong></p>
                        <i class="text-danger" v-if="requestRoute.length < 1">
                          Rute request belum ada
                        </i>
                        <section v-else>
                          <b-badge v-for="(rute, i) in requestRoute" :key="i" variant="light-success" class="mr-1">
                            {{ rute.name }}
                          </b-badge>
                        </section>
                    </b-col>
                </b-row>
            </b-card>
          </b-col>
          <!-- Barang yang sudah disimpan -->
          <b-col cols="12">
            <b-card >
              <header class="d-flex justify-content-between align-items-center">
                <div>
                  <h3>Rincian Ajuan Barang</h3>
                  <div v-if="isAdminGudang && !isSales && !isMyGudangSelected">
                    <strong class="text-danger">
                      Gudang Anda tidak dipilih untuk pengambilan ajuan barang ini
                    </strong>
                  </div>
                </div>
                
                <div v-if="isAdminGudang && !isSales && rb.status > 2" class="flex justify-content-end">
                  <b-badge variant="success">
                    Ajuan Ini Sudah Anda Setujui
                  </b-badge>
                </div>
              </header>
              <b-row>
                <!-- Filter -->
                <b-col cols="12" v-if="isSales || isSPV">
                  <b-row class="justify-content-bet">
                    <b-col md="6" sm="12">
                      <b-button v-if="rb.status < 3 && this.isSales" variant="info" @click.prevent="openModalBarang">
                        Tambah Barang
                      </b-button>
                    </b-col>
                    <b-col v-if="!isSales && rb.status != 5" md="6" sm="12" class="d-flex align-items-center">
                      <span class="mr-1">Pilih Gudang</span>
                      <v-select class="w-100" label="text" multiple v-model="selectedGudangs" :options="gudangs" />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="2" sm="4" class="my-1">
                  <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50">Per page</label>
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage2"
                      size="sm"
                      :options="pageOptions2"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">
                  <b-form-group
                    label="Sort"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="sortBySelect"
                        v-model="sortBy2"
                        :options="sortOptions2"
                        class="w-75"
                      >
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select
                        v-model="sortDesc2"
                        size="sm"
                        :disabled="!sortBy"
                        class="w-25"
                      >
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="my-1">
                  <b-form-group
                    label="Filter"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput2"
                        v-model="filter2"
                        type="search"
                        placeholder="Type to Search"
                      />
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <!-- / -->
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    responsive
                    :per-page="perPage2"
                    :current-page="currentPage2"
                    :items="rincianBarangs"
                    :fields="rincianFields"
                    :sort-by.sync="sortBy2"
                    :sort-desc.sync="sortDesc2"
                    :sort-direction="sortDirection2"
                    :filter="filter2"
                    :filter-included-fields="filterOn2"
                    @filtered="onFiltered2"
                    >
                    <template #cell(remove)="row">
                      <feather-icon v-if="rb.status < 3" @click="remove(row)" class="text-danger" size="24" icon="MinusCircleIcon"></feather-icon>
                      <feather-icon v-if="rb.status == 5" class="text-secondary" size="24" icon="SlashIcon"></feather-icon>
                    </template>
                    <!-- Check box for show detail -->
                    <template #cell(details)="row">
                        <b-form-checkbox
                        v-if="row.item.stocks && row.item.stocks.length > 0"
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        <span class="vs-checkbox">
                            <span class="vs-checkbox--check">
                            <i class="vs-icon feather icon-check" />
                            </span>
                        </span>
                        <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
                        </b-form-checkbox>
                        <span v-else class="text-danger">
                            <feather-icon icon="SlashIcon" />
                        </span>
                    </template>
                    <!-- / -->
                    <!-- Detail -->
                    <template #row-details="{item, toggleDetails}">
                    <b-card>
                        <b-row class="mb-2">
                            <b-col cols="12">
                                <table>
                                    <tr>
                                        <th>#</th>
                                        <th>Gudang</th>
                                        <th>Blok</th>
                                        <th>Palet</th>
                                        <th>Rak</th>
                                        <th>Stok</th>
                                        <th>Stok Disetujui Gudang</th>
                                    </tr>
                                    <tr :key="i" v-for="(stock, i) in item.stocks">
                                        <td>
                                          <b-form-checkbox v-if="stock.stok > 0" v-model="stock.selected"></b-form-checkbox>
                                          <feather-icon icon="SlashIcon" class="text-danger" v-else></feather-icon>
                                        </td>
                                        <td>
                                            {{ stock.gudang ? stock.gudang.nama_gudang : '-' }}
                                        </td>
                                        <td>{{ stock.blok ? `Block ${stock.blok.blok}` : '-' }}</td>
                                        <td>{{ stock.palet ? `Palet ${stock.palet.palet}` : '-' }}</td>
                                        <td>{{ stock.rak ? `Rak 1.${stock.rak.rak}` : '-' }}</td>
                                        <td>{{ stock.stok }}</td>
                                        <td>
                                          <!-- <validation-provider v-if="stock.stok > 0" #default="{errors}" :rules="`max_value:${stock.stok}`"> -->

                                            <b-form-input v-if="stock.stok > 0" v-model="stock.requestedStock"></b-form-input>
                                            <!-- <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider> -->
                                          <span v-else class="text-danger">
                                            <i>Stok Kosong</i>
                                          </span>
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                        </b-row>

                        <b-button
                        size="sm"
                        variant="outline-secondary"
                        @click="toggleDetails"
                        >
                        Hide
                        </b-button>
                    </b-card>
                    </template>
                    <!-- / -->
                    <template #cell(actions)="row">
                      <a href="#" v-if="rb.status < 3" @click.prevent="remove(row)" class="text-danger">
                        <feather-icon icon="MinusCircleIcon" size="24"></feather-icon>
                      </a>
                      <feather-icon v-if="rb.status == 5" icon="XIcon" class="text-secondary"></feather-icon>
                    </template>
                    <template #cell(no)="{ index }">
                      {{ index + 1 }}
                    </template>

                    <template #cell(barang)="{ item }">
                      {{ item.barang ? item.barang.nama : '-' }}
                    </template>
                    <template #cell(satuan)="{ item }">
                      {{ item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-' }}
                    </template>
                    
                    <template #cell(qty_request)="{item}">
                      <span>
                        {{item.qty_request}}
                      </span>
                    </template>

                    <!-- KHUSUS SPV -->
                    <template v-for="(gudang, i) in selectedGudangs" v-slot:[`cell(${getShortName(gudang.text)})`]="{item}">
                      <b-form-input size="sm" type="number" v-model="item[getShortName(gudang.text)]"></b-form-input>   
                    </template>
                    <!-- / -->

                    <!-- KHUSUS GUDANG -->
                    <template #cell(sumStock)="{item, toggleDetails}">
                      <b-button v-if="isAdminGudang && !isSales" size="sm" variant="primary" @click.prevent="toggleDetails">Pilih Stok</b-button>
                      <span v-else>
                        {{ item.tujuan  ? item.tujuan.reduce((total, item) => total += item.qty_real, 0) : 0 }}
                      </span>
                    </template>
                    <!-- / -->

                    <template #cell(appr_spv)="{item}">
                      <span>
                        {{item.appr_spv}}
                      </span>
                    </template>
                    <template #cell(appr_gudang)="{item}">
                      <b-form-input
                        v-if="isAdminGudang"
                        v-model="item.appr_gudang"
                        type="number"
                      />
                      <span v-else>
                        {{item.appr_gudang}}
                      </span>
                    </template>
                    <template #cell(keterangan)="{item}">
                      <b-form-input
                        v-if="isSales || isSPV"
                        v-model="item.keterangan"
                      />
                      <span v-else>{{item.keterangan}}</span>
                    </template>

                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-pagination
                    v-model="currentPage2"
                    :total-rows="rincianBarangs.length"
                    :per-page="perPage2"
                    align="center"
                    size="sm"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="12" class="mb-4">
            <section class="d-flex justify-content-end">
              <b-button v-if="rb.status == 4" disabled>
                Ajuan Barang Selesai
              </b-button>
              <b-button v-else-if="isAdminGudang && !isSales && rb.status <= 3" :disabled="rincianBarangs.length < 1 || !isMyGudangSelected" variant="warning" @click.prevent="confirmSubmitStock">Disetujui</b-button>
              <b-button v-else-if="isAdminGudang && !isSales && rb.status == 4" disabled variant="secondary">Ajuan sudah disetujui</b-button>
              <b-button v-else-if="rb.status != 3 && rb.status != 5" :disabled="rincianBarangs.length < 1" variant="info" @click.prevent="submit">{{ isSales ? 'Kirim Ajuan Barang' : 'Disetujui' }}</b-button>
              <b-button v-else-if="isSales && rb.status == 3" variant="success" @click.prevent="approveSales">Barang Diterima</b-button>
              <b-button v-if="canCancelAjuan" class="ml-2" variant="outline-danger" @click.prevent="cancel">Batalkan</b-button>
              <b-button v-if="rb.status == 5" variant="secondary" disabled>Ajuan telah dibatalkan</b-button>
            </section>
          </b-col>
        </b-row>
      </validation-observer>
      <stock-modal :stocks="stocksModal" :rincian_id="rincianIdModal" :barang="barangModal" @submit="addStock"></stock-modal>
      <checked-barang-modal @removeAll="removeCheckedBarang" :stocks="checkedBarang" />
      <preview-stock-modal @submit="submitStock"></preview-stock-modal>
    </b-overlay>
  </main>
</template>

<script>
import CheckedBarangModal from './components/CheckedBarangModal.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea, BOverlay,BFormCheckbox
} from "bootstrap-vue";
import vSelect from 'vue-select'
import PreviewStockModal from './components/PreviewStockModal.vue'
import mixin from './mixin'

export default {
  mixins: [mixin],
  components: {
    vSelect,
    PreviewStockModal,
    CheckedBarangModal,
    StockModal: () => import('./components/Modal.vue'),
    BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  watch: {
    async selectedGudangs(val) {
      setTimeout(() => {
        this.updateStocks()
      }, 1000)
      this.updateFields()
    },
    allChecked(val) {
      if(val) {
        this.checkedBarang = this.barangs
      }
      else {
        this.checkedBarang = []
      }
    }
  },
  data() {
    return {
      requestRoute: [],
      selectedGudangsId: [],
      deletedGudang: [],
      allChecked: false,
      rincianIdModal: null,
      gudangSales: null,
      gudangs: [],
      selectedGudangs: [],
      checkedBarang: [], // store checkedBarang for insert to rincian
      tableBarang: {
        perPage: 20,
        currentPage: 1,
        filter: '',
        filterOn: []
      },
      showModalTambah: false,
      showModalEdit: false,
      form: {
        id:null,
        barang_id:0,
        qty_request:0,
        appr_spv:0,
        keterangan:"",
      },
      rb:{},
      optstatus:[],
      status:{},
      loading: false,
      barangs: [],
      rawBarangs: [],
      perPage: 20,
      pageOptions: [20, 40, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      barangFields: [
        { key: "checkbox", label: "#" },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "stocks", label: "Jumlah Stok", sortable: true },
        { key: "qty_request", label: "Request"},
        { key: "satuan", label: "Satuan" },
        { key: "converted", label: "Jumlah Konversi"}
      ],
      items: [],
      perPage2: 20,
      pageOptions2: [20, 40, 60],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      rincianFields: [
        { key: "barang", label: "Barang", sortable: true },
        { key: "qty_request", label: "Request Sales", sortable: true },
        { key: "keterangan", label: "keterangan", sortable: true },
      ],
      templateRincian: [
        {
          appr_spv: 0,
          barang_id: null,
          keterangan: null,
          qty_request: 0,
          request_id: null // defaut route.params.id
        }
      ],
      rawSelectedGudangs: [],
      stocksModal: [], // buat store stock available selected barang oleh gudang
      barangModal: {nama: null}, // buat item barang yg dipilih gudang
      rincianBarangs: [],
      showModalBarang: false,
      status: 1, // status ajun barang
      myCancelPermit: false // indikasi bahwa current user bisa cancel atau engga
    };
  },
  computed: {
    canCancelAjuan() { // check apakah ajuan masih bisa dicancel atau engga
      return this.rb.status <= 3 && this.myCancelPermit == true
    },
    isMyGudangSelected() {
      return this.selectedGudangsId.includes(this.myGudang.id)
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.rincianFields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    }
  },
  created(){
    this.checkCancelPermission()
    this.getGudangs()
    if(this.isSales) {
      this.getAllStock()
    }
  },
  async mounted() {
    this.loading = true
    // get request barang
    await this.getData()
    await this.getRequestRute()
    await this.setFieldRincian()
    await this.getRincianBarang()
    // get sales gudang
    this.gudangSales = await this.getGudangSales(this.rb)
    this.loading = false
  },
  methods: {
    async getRequestRute() {
      const sales_id = this.rb && this.rb.sales ? this.rb.sales.id : null
      const params = {
        request_id: this.$route.params.id,
        sales_id: this.myGudang && this.isSales && this.user && this.user.karyawan ? this.user.karyawan.id : sales_id
      }

      const requestRoute = await this.$store.dispatch('requestbarang/getRoute', params)
      if(requestRoute.length > 0) {
        this.requestRoute = requestRoute.filter(item => item.rute)
        this.requestRoute = this.requestRoute.map(item => ({
          name: item.rute.nama_rute
        }))
      }
    },
    cancel() {
      this.$swal({
        title: "Anda yakin?",
        text: `Anda akan membatalkan ajuan barang ini`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,    
      })
      .then(async res => {
        if(res.value) {
          this.loading = true
          await this.saveAjuanBarang(5)
          this.loading = false
          this.displaySuccess({
            message: 'Ajuan telah dibatalkan'
          })
          this.$router.push(`/ajuanbarang`)

        }
      })
    },
    async checkCancelPermission() {
      const parentMenu = await this.currentMenu(this.$route.meta.parent)
      const params = {
        level_id: this.user.level.id
      }
      if(parentMenu) params.menu_id = parentMenu.id
      const status = await this.$store.dispatch('statusrole/getData', params)
      const findCancel = status.find(st => st.data_status == 5)

      this.myCancelPermit = findCancel ? true : false
    },
    async printSuratJalan() {
      const request_id = this.$route.params.id
      const payload = {
        request_id,
        id_gudang: this.myGudang.id
      }
      try {
        this.loading = true
        // create surat jalan
        const suratJalan = await this.$store.dispatch('requestbarang/createSuratJalan', [payload])

        // download surat jalan
        const params = {
          request_id,
          gudangId: this.myGudang.id
        }
        const response = await this.$store.dispatch('requestbarang/printSuratJalan', params)
        this.loading = false
        window.open(URL.createObjectURL(response))
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    filterBarangMobil(e) {
      let search = e.target.value.toLowerCase()
      const barangs = Object.assign([], this.rawBarangs)
      const filteredBarangs = barangs.filter(barang => {
        return barang.nama.toLowerCase().indexOf(search) > -1
      })
      this.allChecked = false
      this.barangs = filteredBarangs
    },
    async onSatuanKonversiSelected(index) {
      const barangs = Object.assign([], this.barangs)
      const barang = barangs[index]
      const qtyRequest = barang.qty_request

      if(barang.selectedKonversi && qtyRequest > 0) {
        const konversi = barang.selectedKonversi
        
        // set payload for konversi
        const payload = {
          tipe: 2, // konversi ke satuan terkecil, 1 sebaliknya
          id_barang: konversi.id_barang,
          id_satuan: konversi.id_satuan,
          jumlah: qtyRequest
        }
        const hasilKonversi = await this.$store.dispatch('konversi/konversiBarang', payload)
        barangs[index].converted = hasilKonversi

        // update reactivity of array
        this.barangs = barangs
      }
    },
    removeCheckedBarang(checkedBarang) {
      this.checkedBarang = checkedBarang
      this.$bvModal.hide('checked-barang-modal')
    },
    viewCheckedBarang() {
      this.$bvModal.show('checked-barang-modal')
    },
    async submitStock(payloads) { // after confirm, update stock
      this.$swal({
        title: "Anda yakin?",
        text: `Stok digudang akan berkurang`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then(async res => {
          if(res.value ) {
      
            try {
              let i = 0
              await payloads.map(async payload => {
                try {
                  // PR JIKA ada yang sudah appr_gudang == 1 defaut jangan submit lagi
                  await this.$store.dispatch('requestbarangrincian/saveApproveGudang', payload)
                  this.displaySuccess({
                    message: 'Stok sudah dipilih'
                  })
                }
                catch(e) {
                  this.displayError(e)
                }
                finally {
                  // push notif supaya notif berkurang
                  const salesPayload = [{
                    karyawan_id: this.rb.sales ? this.rb.sales.id : null,
                    level: 7,
                    modul: 'Ajuan Barang',
                    keterangan: 'Gudang menyetujui Ajuan Barang Sales',
                    id: this.rb.id
                  }]
                  const spvPayload = [{
                    karyawan_id: this.rb.sales ? this.rb.sales.id : null,
                    level: 6,
                    modul: 'Ajuan Barang',
                    keterangan: 'Gudang menyetujui Ajuan Barang Sales',
                    id: this.rb.id
                  }]
                  const datas = [...salesPayload, ...spvPayload]
                  await this.$store.dispatch('notifikasi/save', datas)
                  // send notif to mobile via socket
                  const payload = {
                    key: 'ajuan_barang',
                    value: {
                      level: this.user.level ? this.user.level.id : 0,
                      data: this.rb
                    }
                  }

                  this.eventSocket(payload.key, payload.value)
                }
              })
              
              // reset selected modal
              this.$store.commit('requestbarang/SET_SELECTED_STOCK', [])
              this.$bvModal.hide('form-preview-stock')

              await this.getAjuanBarang()
              
              this.getData()
            }
            catch(e) {
              console.error(e)
              this.displayError(e)
              return false
            }
          }
        })
    },
    async confirmSubmitStock() { // validate and show preview
          const payloads = []
          let error = null

          // Validate payload rincian first
          await this.rincianBarangs.map(rincian => {
            const maxStock = rincian.spv_request
            let payload = {
              nama: rincian.barang ? rincian.barang.nama : null,
              request: maxStock,
              sumStock: 0,
              message: null,
              is_error: false
            }

            // validation if user selected stock less than requestedStock
            const selectedStocks = rincian.stocks.filter(item => item.selected)
            const overStock = selectedStocks.some(item => item.stok < item.requestedStock)
            if(overStock) {
              error = 'Pastikan stok yang dipilih memadai request!'
            }
            
            if(rincian.tujuan) {
                
              const rincianTujuan = rincian.tujuan
              rincianTujuan.map(tujuan => { // per barang
                const isMyGudang = tujuan.gudang_id == this.myGudang.id
                if(isMyGudang) {
                  const requestStocks = []
                  if(rincian.stocks && rincian.stocks.length > 0) {

                    rincian.stocks.map(stock => {
                      if(stock.selected) {
                        requestStocks.push(stock)
                      }
                    })
  
                    if(requestStocks.length < 1) {
                      
                      error =  'Harap pilih stok untuk setiap barang!'
                      this.$store.commit('requestbarang/SET_SELECTED_STOCK', [])
                      return false
                    }
  
                    payload.data = {
                      id_request_tujuan: tujuan.id,
                      id_gudang: this.gudangSales ? this.gudangSales.id : null,
                      id_barang: rincian.barang ? rincian.barang.id : null,
                      kirim: requestStocks.map(stock => ({id_penyimpanan: stock.id, qty: parseInt(stock.requestedStock)}))
                    }
  
                    // 
                    const sumRequestStock = payload.data.kirim.reduce((total, item) => total += item.qty, 0)
                    if(sumRequestStock > maxStock) {
                      payload.is_error = true
                      payload.message = 'Pilihan stok melebihi dari request'
                    }
                    payload.sumStock = payload.data.kirim.reduce((total, item) => total += item.qty, 0)
        
                    // send to paylods
                    payloads.push(payload)
                  }
                }
              })
            }
          })

          if(error) {
            this.displayError({
              message: error
            })
            return false
          }

          // save to store and open the preview modal
          this.$store.commit('requestbarang/SET_SELECTED_STOCK', payloads)
          this.$bvModal.show('form-preview-stock')

    },
    async getAjuanBarang() {
      let params = this.isSales ? { sales_id: this.user.karyawan.id, order: 'desc' } : {order: 'desc'}
      if(this.isAdminGudang) {
        params.order_status = 'desc'
      }
      
      if(this.selectedStatus && this.selectedStatus != 'all') {
        params.status = this.selectedStatus
      }
      this.loading = true
      await this.$store.dispatch('requestbarang/getData', params)
      this.loading = false
    },
    addStock({selectedStocks, rincian_id}) {
      const rincianBarangs = JSON.parse( JSON.stringify(this.rincianBarangs) )
      rincianBarangs.map(async rincian => {
        if(rincian.id == rincian_id) {
          // rincian.sumStock = await selectedStocks.reduce((total, item) => total += item.jumlah, 0)
          rincian.sumStock = selectedStocks[selectedStocks.length - 1].jumlah
          rincian.stocks = selectedStocks
        }
      })
      this.rincianBarangs = rincianBarangs
      this.$bvModal.hide('stock-modal')
    },
    // Gudang memilih stok untuk setiap barang
    async pilihStock(rincian) {
      this.stocksModal = []
      this.barangModal = {nama:null}
      this.rincianIdModal = null

      // get stock per barang
      const barang = rincian.barang ? rincian.barang : null
      if(!barang) {
        this.displayError({
          message: 'Barang tidak ada di rincian'
        })
        return false
      }

      const stocks = await this.$store.dispatch('penyimpanan/getData', {
        barang_id: barang.id,
        gudang_id: this.myGudang.id
      })
      this.stocksModal = stocks
      this.barangModal = barang
      this.rincianIdModal = rincian.id
      this.$bvModal.show('stock-modal')
    },
    updateFields() {
      let rincianFields = [
          { key: "actions", label: "" }, 
          { key: "barang", label: "Barang", sortable: true },
          { key: "satuan", label: "Satuan" },
          { key: "qty_request", label: "Request Sales", sortable: true }
      ]
      
      if(this.selectedGudangs.length > 0) {
        this.selectedGudangs.map(gudang => {
          const newItem = {
            key: this.getShortName(gudang.text),
            label: gudang.text
          }
          rincianFields.push(newItem)
        })
      }

      rincianFields.push({ key: "keterangan", label: "keterangan", sortable: true })

      setTimeout(() => {
        this.rincianFields = rincianFields
      }, 500)
      
    },
    updateStocks() { // ketika SPV memilih gudang, munculkan stock per gudang dari list barang rincian
      if(this.selectedGudangs.length > 0 && this.rincianBarangs.length > 0) {

        // function untuk menyamakan stock berlebih dengan request sales
        function getSelisih(requestSales, target, stock) {
          for(let i = 0; i < stock; i++) {
            if(requestSales < target) {
              requestSales++
            }
          }

          return requestSales
        }

        // get barang rincian id
        const barangsId = this.rincianBarangs.map(rincian => rincian.barang ? rincian.barang.id : null).filter(item => item != null)
        
        // call api
        this.selectedGudangs.map(async (gudang, index_gudang) => {
          const stocks = await this.$store.dispatch('penyimpanan/getData', {
            barang_id: barangsId.join(','),
            gudang_id: gudang.value
          })
          this.rincianBarangs.map(rincian => {
            rincian.penyimpanan = []
            const stockCurrentBarang = stocks.filter(stock => (stock.barang && rincian.barang) && stock.barang.id == rincian.barang.id)
            const stockPenyimpanans = []
            const sumStockCurrentBarang = stockCurrentBarang.reduce((total, item) => total += item.jumlah, 0)
            
            // Menentukan request sales
            let requestSales = 0
            rincian[ this.getShortName(gudang.text) + '_jumlah' ] = stockCurrentBarang.reduce((total, item) => total += item.stok, 0)
            
            stockCurrentBarang.map(stock => {
              // requestSales += stock.jumlah
              if(requestSales < rincian.qty_request) {
                const res = requestSales + stock.stok
                if(res >= rincian.qty_request) {
                  requestSales = getSelisih(requestSales, rincian.qty_request, stock.stok)
                }
                else{
                  requestSales += stock.stok
                }
              }
            })
            rincian[ this.getShortName(gudang.text) ] = rincian[ this.getShortName(gudang.text) ] ? rincian[ this.getShortName(gudang.text) ] : requestSales
          })

          if(index_gudang == this.selectedGudangs.length - 1) {
            const updatedRincian = Object.assign([], this.rincianBarangs)
            this.rincianBarangs = []
            this.rincianBarangs = updatedRincian
          }
        })
      }
    },
    approveSales() { // untuk sales menandai bahwa request nya sudah sesuai dan agar masuk ke stock mobil
      this.$swal({
        title: "Anda yakin?",
        text: "Anda akan menandai selesai ajuan barang ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false
      })
      .then(async res => {
        if(res.value) {
          try {
            this.loading = true
            // send to approve sales
            let payloadTujuan = []
            this.rincianBarangs.map(rincian => {
              if(rincian.tujuan) {
                rincian.tujuan.map(tujuan => {
                  payloadTujuan.push({
                    id_request_tujuan: tujuan.id
                  })
                })
              }
            })
            const response = await this.$store.dispatch('requestbarangrincian/approveSales', payloadTujuan)
            const key = 'ajuan_barang'
            const value = {
              level: this.user.level.id,
              data: response
            }

            this.eventSocket(key, value)
            
            this.loading = false
            this.displaySuccess({
              message: 'Data berhasil disimpan'
            })
            setTimeout(() => {
              this.$router.push('/ajuanbarang')
            }, 100)
          }
          catch(e) {
            this.loading = false
            this.displayError(e)
            return false
          }
        }
      })
      

    },
    async saveAjuanBarang(status = 1) {
      const payload = this.rb
      payload.status = status // DISETJUI SPV
      const response = await this.$store.dispatch('requestbarang/save', [payload])
      return Promise.resolve(response.data)
    },
    async getRincianBarang() {
      await this.$store.dispatch("requestbarangrincian/getData", {request_id: this.$route.params.id})

      //  get data rincian
      let listRequestedBarangs = this.$store.state.requestbarangrincian.datas
      if(this.isAdminGudang || this.isSales) {
       listRequestedBarangs = await this.setRincianDetail(listRequestedBarangs)
       this.selectedGudangsId = await this.getSelectedGudang(listRequestedBarangs)
       
      }
      else { // spv
        // set for detail like gudang1 => 10, gudang2 => 23
        listRequestedBarangs = this.setRincianGudang(listRequestedBarangs)
      }

      
      this.rincianBarangs = []
      setTimeout(() => {
        this.rincianBarangs = listRequestedBarangs
        this.rincianBarangs.map(rincian => {
          if(rincian.stocks) {
            rincian.stocks.map(stock => {
              stock.selected = false
              stock.requestedStock = rincian.qty_request > stock.stok ? stock.stok : rincian.qty_request
              if(rincian.tujuan) {
                stock.requestedStock = rincian.tujuan.reduce((total, tj) => total += tj.qty_real, 0)
              }
            })
          }
        })
        this.totalRows2 = listRequestedBarangs.length
      }, 1000)
    },
    getMergeGudang() {
      const result = Object.assign([], this.rawSelectedGudangs)
      this.selectedGudangs.map(gd => {
        if(!result.find(item => item.value == gd.value)) {
          result.push(gd)
        }
      })

      return result
    },
    async saveRincianTujuan() {
      if(this.isSPV || this.isAdminGudang) {
  
        // check first for get id tujuan if exist
        const tujuans = await this.$store.dispatch('requestbarangrincian/getTujuan', {
          rincian_request_id: this.rincianBarangs.map(rincian => rincian.id).join(',')
        })
  
        const payloads = []
        const payloadsApprove = []
        const mergeSelectedGudangs = this.getMergeGudang()
        mergeSelectedGudangs.map(async gudang => {

          const gudangKey = this.getShortName(gudang.text)
          this.rincianBarangs.map(rincian => {
            const hasTujuan = tujuans.find(tujuan => tujuan.rincian_request_id == rincian.id && tujuan.gudang_id == gudang.value)
            let payload = {
              rincian_request_id: rincian.id,
              gudang_id: gudang.value,
              qty_request: rincian[gudangKey],
              appr_gudang: 0,
              appr_sales: 0,
              qty_real: 0,
              keterangan: rincian.keterangan ? rincian.keterangan : '-'
            }
            if(hasTujuan) {
              payload.id = hasTujuan.id // tujuan id
            }

            if(this.deletedGudang.find(dl => dl.value == gudang.value)) {
              payload.fungsi = 2
            }

            payloads.push(payload)
          })
        })
        // start insert
        try {
            await this.$store.dispatch('requestbarangrincian/saveTujuan', payloads)
            setTimeout(() => this.$router.push('/ajuanbarang'), 1000)
        }
        catch(e) {
          this.displayError(e)
          return false
        }
  
      }
    },
    async getAllStock() { // khusus untuk sales ambil semua stock
      const stocks = await this.$store.dispatch('penyimpanan/getData')
      const barangs = []

      // get barang data
      stocks.map(item => {
        const find = barangs.find(barang => item.barang && barang.id == item.barang.id)
        if(!find) {
          let barang = item.barang
          if(barang) {
            barang.stocks = []
            barang.qty = 0
            barangs.push(barang)
          }
        }
      })

      barangs.map(barang => {
        barang.qty_request = 0
        barang.converted = 0
        barang.selectedKonversi = null
        const barangStocks = stocks.filter(stock => stock.barang && stock.barang.id == barang.id)
        
        if(barangStocks) {
          barangStocks.map(stock => {
            delete stock.barang
            barang.stocks.push(stock)
          })
        }

        if(barang.konversi && barang.konversi.length > 0) {
          barang.konversi.map(konversi => {
            konversi.value = konversi
            konversi.text = konversi.satuan ? konversi.satuan.satuan : konversi.id_satuan
          })
        }
      })

      this.barangs = barangs
      this.rawBarangs = barangs
    },
    async getGudangs() {
      const gudangs = await this.$store.dispatch('gudang/getData', {jenis: 1})
      gudangs.map(gudang => {
        this.gudangs.push({
          value: gudang.id,
          text: gudang.nama_gudang
        })
      })
    },
    setFieldRincian() {
      if(this.isSales) {
        if(this.rb.status == 3) {
          this.rincianFields = this.rincianSalesFields
        }
        else {
          this.rincianFields = this.rincianDefaultFields
        }
      }
      else if(this.isSPV){
        this.rincianFields = this.rincianSpvFields
      }
      else {
        this.rincianFields = this.rincianGudangFields
      }
    },
    endChooseBarang() {
      this.checkedBarang.map(brg => {
        const qtyRequest = brg.konversi && brg.konversi.length > 0 && brg.selectedKonversi ? brg.converted : brg.qty_request
        const item = {
          barang: {
            id: brg.id,
            nama: brg.nama,
            satuan: brg.satuan
          },
          appr_spv: 0,
          keterangan: null,
          qty_request: qtyRequest,
          request:this.rb
        }
        
        this.rincianBarangs.push(item)
      })

      this.showModalBarang = false
      this.checkedBarang = []
    },
    onBarangFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.tableBarang.currentPage = 1;
    },
    getTotal(stocks) {
      return stocks.reduce((total, item) => item.jumlah + total, 0)
    },
    openModalBarang() {
      // default checklist all
      this.allChecked = true
      this.showModalBarang = true
      
    },
    remove({index, item}) {
      const isNewData = item.id ? false : true
      if(isNewData) {
        this.rincianBarangs.splice(index, 1)
      }
      else { // delete to API

        this.$swal({
          title: "Anda yakin?",
          text: `Data Rincian Ajuan Barang ini akan dihapus`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            item.fungsi = 1; // soft delete
            this.$store
              .dispatch("requestbarangrincian/save", [item])
              .then(() => {
                this.resetForm();
                this.getData();
                this.getRincianBarang();
              })
              .catch((e) => {
                this.displayError(e);
                return false;
              });
          }
        });
      }
    },
    resetForm() {
      this.form.barang_id = null;
      this.form.qty_request = null;
      this.form.keterangan = null;
      this.showModalTambah = false;
      this.showModalEdit = false;
    },
    edit(item) {
      let { id } = item;
      this.form.barang_id = id;
      this.showModalEdit = true;
    },
    getDeletedGudang() {
      const result = []
      this.rawSelectedGudangs.map(item => {
        if(!this.selectedGudangs.find(gd => gd.value == item.value)) {
          result.push(item)
        }
      })

      return result
    },
    submit() {

      if(this.isSPV ) {
        // check if selected gudang was removed
        this.deletedGudang = this.getDeletedGudang()


        if(this.selectedGudangs.length < 1) {
          this.displayError({
            message: 'Harap Pilih Gudang Penyimpanan!'
          })

          return false
        }

        // if spv, validasi jangan ada nol
        let emptyStockGudang = []
        this.selectedGudangs.map(gudang => {
          const key = this.getShortName(gudang.text)
          const emptyStocks = this.rincianBarangs.filter(rincian => rincian[key] < 1)
          if(emptyStocks.length > 0) {
            emptyStockGudang = emptyStockGudang.concat(emptyStocks)
          }
        })

        if(emptyStockGudang.length > 0) {
          this.displayError({
            message: 'Stok Barang di gudang yang dipilih tidak boleh kosong!'
          })
          return false
        }
      }

      this.$swal({
        title: "Anda yakin?",
        text: "Anda akan menyetujui ajuan barang ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false
      })
      .then(res => {
        if(res.value) {
          // check if selected gudang was deleted
          this.rawSelectedGudangs.map(item => {
            if(!this.selectedGudangs.find(gd => gd.value == item .value)) {
              this.deletedGudang.push(item)
            }
          })
          // set payloads
          let payloads = []
    
          // set payload of update for request tujuan dan approve gudang endpoint
          let tujuanPayload = []
          this.rincianBarangs.map(rincian => {
            let payload = {
              barang_id: rincian.barang.id,
              appr_spv: this.isSales && (status == null || status == 1) ? 0 : 1,
              appr_sales: 0,
              qty_request: rincian.qty_request,
              keterangan: rincian.keterangan ? rincian.keterangan : '-',
              request_id: rincian.request.id
            }
            if(rincian.id) {
              payload.id = rincian.id
            }
            payloads.push(payload)
          })
    
          this.loading = true
    
          this.$store
            .dispatch("requestbarangrincian/save", payloads)
            .then(async () => {
              this.loading = false
              this.checkedBarang = []
              
              if(this.isSPV) {
                this.status = 2
                // send to tujuan
                const response = await this.saveAjuanBarang(this.status)
                await this.saveRincianTujuan()

                // send notif to mobile via socket
                const payload = {
                  key: 'ajuan_barang',
                  value: {
                    level: this.user.level ? this.user.level.id : 0,
                    data: response
                  }
                }

                this.eventSocket(payload.key, payload.value)
              }
              else if(this.isAdminGudang) {
                this.status = 3
              }
              
              this.rincianBarangs = []
              await this.getRincianBarang()
              this.setFieldRincian()
              this.displaySuccess({
                message: 'Berhasil'
              })
            })
            .catch((e) => {
              this.loading = false
              this.displayError(e)
              console.error(e)
              return false
            });
        }
      })
    },
    add(item) {
      let { id } = item;
      this.id = id;
      this.showModalTambah = true;
    },
    getTotal(stocks) {
      return stocks.reduce((total, item) => item.jumlah + total, 0);
    },
    async getStockBarang(barangsId) {
      const params = {barang_id: barangsId.join(',')}
      const stocks = await this.$store.dispatch('penyimpanan/getData', params)

      // add stocks to data barang
      this.barangs.map(barang => {
        barang.stocks = []
        const findStocks = stocks.filter(stock => stock.barang && stock.barang.id == barang.id)

        if(findStocks && findStocks.length > 0) {
            findStocks.forEach(stock => {
                barang.stocks.push({
                    id: stock.id,
                    jumlah: stock.jumlah,
                    gudang: stock.gudang,
                    blok: stock.blok,
                    rak: stock.rak,
                    laci: stock.laci
                })
            })
        }
      })
    },
    viewDetail(item) {
      this.$store.commit("penyimpanan/SET_DETAIL_DATA", item);
      setTimeout(
        () => this.$router.push({ name: "route-stock-in-edit", params: { id: item.id } }),
        500
      );
    },
    async getData() {
        const rb = await this.$store.dispatch("requestbarang/getDataById", this.$route.params.id)
        this.rb = rb
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
  }
};
</script>
