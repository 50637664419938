var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_vm.isSales || _vm.isSPV ? _c('b-modal', {
    attrs: {
      "id": "modal-barang",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "no-close-on-backdrop": "",
      "ok-only": "",
      "centered": "",
      "size": "xl",
      "title": "Cari Barang Untuk Diajukan"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex w-100 justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.endChooseBarang($event);
            }
          }
        }, [_vm._v(" Selesai ")])], 1)];
      },
      proxy: true
    }], null, false, 2537386560),
    model: {
      value: _vm.showModalBarang,
      callback: function callback($$v) {
        _vm.showModalBarang = $$v;
      },
      expression: "showModalBarang"
    }
  }, [_c('b-row', {
    staticClass: "mb-2 justify-content-end"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.filterBarangMobil($event);
      }
    }
  }), _c('b-input-group-append', [_c('b-button', [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1), _c('b-button', {
    staticClass: "d-flex align-items-center mb-2",
    attrs: {
      "variant": "success",
      "disabled": _vm.checkedBarang.length < 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.viewCheckedBarang($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "ShoppingBagIcon",
      "size": "20"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.checkedBarang.length) + " barang dipilih.")])], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.tableBarang.perPage,
      "current-page": _vm.tableBarang.currentPage,
      "fields": _vm.barangFields,
      "items": _vm.barangs,
      "filter": _vm.tableBarang.filter,
      "filter-included-fields": _vm.tableBarang.filterOn
    },
    on: {
      "filtered": _vm.onBarangFiltered
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function callback($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.checkedBarang,
            callback: function callback($$v) {
              _vm.checkedBarang = $$v;
            },
            expression: "checkedBarang"
          }
        })];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [item.konversi && item.konversi.length < 1 ? _c('span', [_vm._v(_vm._s(item.satuan ? item.satuan.satuan : '-'))]) : _c('b-form-select', {
          attrs: {
            "options": item.konversi
          },
          on: {
            "change": function change($event) {
              return _vm.onSatuanKonversiSelected(index);
            }
          },
          model: {
            value: item.selectedKonversi,
            callback: function callback($$v) {
              _vm.$set(item, "selectedKonversi", $$v);
            },
            expression: "item.selectedKonversi"
          }
        })];
      }
    }, {
      key: "cell(stocks)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.stocks && item.stocks.length > 0 ? item.stocks.reduce(function (total, stock) {
          return stock.stok > 0 ? total += stock.stok : total += 0;
        }, 0) : 0) + " " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(qty_request)",
      fn: function fn(_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          on: {
            "keyup": function keyup($event) {
              return _vm.onSatuanKonversiSelected(index);
            }
          },
          model: {
            value: item.qty_request,
            callback: function callback($$v) {
              _vm.$set(item, "qty_request", $$v);
            },
            expression: "item.qty_request"
          }
        })];
      }
    }, {
      key: "cell(converted)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.konversi && item.konversi.length > 0 ? _c('strong', [_vm._v(" " + _vm._s(item.converted) + " "), item.converted > 0 ? _c('span', [_vm._v(_vm._s(item.satuan ? item.satuan.satuan.toLowerCase() : ''))]) : _vm._e()]) : _c('i', {
          staticClass: "text-danger"
        }, [_c('small', [_vm._v("Satuan Konversi belum diinput")])])];
      }
    }], null, false, 3320460238)
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.barangs.length,
      "per-page": _vm.tableBarang.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.tableBarang.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.tableBarang, "currentPage", $$v);
      },
      expression: "tableBarang.currentPage"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_vm.isAdminGudang && !_vm.isSales && _vm.rb.status >= 3 ? _c('div', {
    staticClass: "d-flex justify-content-end mb-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.printSuratJalan($event);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Cetak Surat Jalan")], 1)], 1) : _vm._e(), _c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Kode Ajuan Barang")])]), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.rb.no))])])]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal Pengajuan")])]), _c('p', [_vm._v(_vm._s(_vm.humanDateTime(_vm.rb.tanggal_waktu)))])]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Nama Sales")])]), _c('p', [_vm._v(_vm._s(_vm.rb.sales ? _vm.rb.sales.nama_lengkap : '-'))])]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.rb.keterangan ? _vm.rb.keterangan : '-'))])]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Rute Ajuan Barang")])]), _vm.requestRoute.length < 1 ? _c('i', {
    staticClass: "text-danger"
  }, [_vm._v(" Rute request belum ada ")]) : _c('section', _vm._l(_vm.requestRoute, function (rute, i) {
    return _c('b-badge', {
      key: i,
      staticClass: "mr-1",
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v(" " + _vm._s(rute.name) + " ")]);
  }), 1)])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('header', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h3', [_vm._v("Rincian Ajuan Barang")]), _vm.isAdminGudang && !_vm.isSales && !_vm.isMyGudangSelected ? _c('div', [_c('strong', {
    staticClass: "text-danger"
  }, [_vm._v(" Gudang Anda tidak dipilih untuk pengambilan ajuan barang ini ")])]) : _vm._e()]), _vm.isAdminGudang && !_vm.isSales && _vm.rb.status > 2 ? _c('div', {
    staticClass: "flex justify-content-end"
  }, [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Ajuan Ini Sudah Anda Setujui ")])], 1) : _vm._e()]), _c('b-row', [_vm.isSales || _vm.isSPV ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-bet"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_vm.rb.status < 3 && this.isSales ? _c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openModalBarang($event);
      }
    }
  }, [_vm._v(" Tambah Barang ")]) : _vm._e()], 1), !_vm.isSales && _vm.rb.status != 5 ? _c('b-col', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v("Pilih Gudang")]), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "label": "text",
      "multiple": "",
      "options": _vm.gudangs
    },
    model: {
      value: _vm.selectedGudangs,
      callback: function callback($$v) {
        _vm.selectedGudangs = $$v;
      },
      expression: "selectedGudangs"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions2
    },
    model: {
      value: _vm.perPage2,
      callback: function callback($$v) {
        _vm.perPage2 = $$v;
      },
      expression: "perPage2"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions2
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy2,
      callback: function callback($$v) {
        _vm.sortBy2 = $$v;
      },
      expression: "sortBy2"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc2,
      callback: function callback($$v) {
        _vm.sortDesc2 = $$v;
      },
      expression: "sortDesc2"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput2",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter2,
      callback: function callback($$v) {
        _vm.filter2 = $$v;
      },
      expression: "filter2"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage2,
      "current-page": _vm.currentPage2,
      "items": _vm.rincianBarangs,
      "fields": _vm.rincianFields,
      "sort-by": _vm.sortBy2,
      "sort-desc": _vm.sortDesc2,
      "sort-direction": _vm.sortDirection2,
      "filter": _vm.filter2,
      "filter-included-fields": _vm.filterOn2
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "filtered": _vm.onFiltered2
    },
    scopedSlots: _vm._u([{
      key: "cell(remove)",
      fn: function fn(row) {
        return [_vm.rb.status < 3 ? _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "size": "24",
            "icon": "MinusCircleIcon"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(row);
            }
          }
        }) : _vm._e(), _vm.rb.status == 5 ? _c('feather-icon', {
          staticClass: "text-secondary",
          attrs: {
            "size": "24",
            "icon": "SlashIcon"
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell(details)",
      fn: function fn(row) {
        return [row.item.stocks && row.item.stocks.length > 0 ? _c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function callback($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? 'Hide' : 'Show'))])]) : _c('span', {
          staticClass: "text-danger"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SlashIcon"
          }
        })], 1)];
      }
    }, {
      key: "row-details",
      fn: function fn(_ref6) {
        var item = _ref6.item,
          toggleDetails = _ref6.toggleDetails;
        return [_c('b-card', [_c('b-row', {
          staticClass: "mb-2"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v("Gudang")]), _c('th', [_vm._v("Blok")]), _c('th', [_vm._v("Palet")]), _c('th', [_vm._v("Rak")]), _c('th', [_vm._v("Stok")]), _c('th', [_vm._v("Stok Disetujui Gudang")])]), _vm._l(item.stocks, function (stock, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [stock.stok > 0 ? _c('b-form-checkbox', {
            model: {
              value: stock.selected,
              callback: function callback($$v) {
                _vm.$set(stock, "selected", $$v);
              },
              expression: "stock.selected"
            }
          }) : _c('feather-icon', {
            staticClass: "text-danger",
            attrs: {
              "icon": "SlashIcon"
            }
          })], 1), _c('td', [_vm._v(" " + _vm._s(stock.gudang ? stock.gudang.nama_gudang : '-') + " ")]), _c('td', [_vm._v(_vm._s(stock.blok ? "Block ".concat(stock.blok.blok) : '-'))]), _c('td', [_vm._v(_vm._s(stock.palet ? "Palet ".concat(stock.palet.palet) : '-'))]), _c('td', [_vm._v(_vm._s(stock.rak ? "Rak 1.".concat(stock.rak.rak) : '-'))]), _c('td', [_vm._v(_vm._s(stock.stok))]), _c('td', [stock.stok > 0 ? _c('b-form-input', {
            model: {
              value: stock.requestedStock,
              callback: function callback($$v) {
                _vm.$set(stock, "requestedStock", $$v);
              },
              expression: "stock.requestedStock"
            }
          }) : _c('span', {
            staticClass: "text-danger"
          }, [_c('i', [_vm._v("Stok Kosong")])])], 1)]);
        })], 2)])], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.rb.status < 3 ? _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.remove(row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          }
        })], 1) : _vm._e(), _vm.rb.status == 5 ? _c('feather-icon', {
          staticClass: "text-secondary",
          attrs: {
            "icon": "XIcon"
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell(no)",
      fn: function fn(_ref7) {
        var index = _ref7.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(barang)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : '-') + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-') + " ")];
      }
    }, {
      key: "cell(qty_request)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.qty_request) + " ")])];
      }
    }, _vm._l(_vm.selectedGudangs, function (gudang, i) {
      return {
        key: "cell(".concat(_vm.getShortName(gudang.text), ")"),
        fn: function fn(_ref11) {
          var item = _ref11.item;
          return [_c('b-form-input', {
            attrs: {
              "size": "sm",
              "type": "number"
            },
            model: {
              value: item[_vm.getShortName(gudang.text)],
              callback: function callback($$v) {
                _vm.$set(item, _vm.getShortName(gudang.text), $$v);
              },
              expression: "item[getShortName(gudang.text)]"
            }
          })];
        }
      };
    }), {
      key: "cell(sumStock)",
      fn: function fn(_ref12) {
        var item = _ref12.item,
          toggleDetails = _ref12.toggleDetails;
        return [_vm.isAdminGudang && !_vm.isSales ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return toggleDetails($event);
            }
          }
        }, [_vm._v("Pilih Stok")]) : _c('span', [_vm._v(" " + _vm._s(item.tujuan ? item.tujuan.reduce(function (total, item) {
          return total += item.qty_real;
        }, 0) : 0) + " ")])];
      }
    }, {
      key: "cell(appr_spv)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.appr_spv) + " ")])];
      }
    }, {
      key: "cell(appr_gudang)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm.isAdminGudang ? _c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.appr_gudang,
            callback: function callback($$v) {
              _vm.$set(item, "appr_gudang", $$v);
            },
            expression: "item.appr_gudang"
          }
        }) : _c('span', [_vm._v(" " + _vm._s(item.appr_gudang) + " ")])];
      }
    }, {
      key: "cell(keterangan)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_vm.isSales || _vm.isSPV ? _c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function callback($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        }) : _c('span', [_vm._v(_vm._s(item.keterangan))])];
      }
    }], null, true)
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.rincianBarangs.length,
      "per-page": _vm.perPage2,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage2,
      callback: function callback($$v) {
        _vm.currentPage2 = $$v;
      },
      expression: "currentPage2"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('section', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.rb.status == 4 ? _c('b-button', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(" Ajuan Barang Selesai ")]) : _vm.isAdminGudang && !_vm.isSales && _vm.rb.status <= 3 ? _c('b-button', {
    attrs: {
      "disabled": _vm.rincianBarangs.length < 1 || !_vm.isMyGudangSelected,
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmSubmitStock($event);
      }
    }
  }, [_vm._v("Disetujui")]) : _vm.isAdminGudang && !_vm.isSales && _vm.rb.status == 4 ? _c('b-button', {
    attrs: {
      "disabled": "",
      "variant": "secondary"
    }
  }, [_vm._v("Ajuan sudah disetujui")]) : _vm.rb.status != 3 && _vm.rb.status != 5 ? _c('b-button', {
    attrs: {
      "disabled": _vm.rincianBarangs.length < 1,
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.isSales ? 'Kirim Ajuan Barang' : 'Disetujui'))]) : _vm.isSales && _vm.rb.status == 3 ? _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.approveSales($event);
      }
    }
  }, [_vm._v("Barang Diterima")]) : _vm._e(), _vm.canCancelAjuan ? _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancel($event);
      }
    }
  }, [_vm._v("Batalkan")]) : _vm._e(), _vm.rb.status == 5 ? _c('b-button', {
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_vm._v("Ajuan telah dibatalkan")]) : _vm._e()], 1)])], 1)], 1), _c('stock-modal', {
    attrs: {
      "stocks": _vm.stocksModal,
      "rincian_id": _vm.rincianIdModal,
      "barang": _vm.barangModal
    },
    on: {
      "submit": _vm.addStock
    }
  }), _c('checked-barang-modal', {
    attrs: {
      "stocks": _vm.checkedBarang
    },
    on: {
      "removeAll": _vm.removeCheckedBarang
    }
  }), _c('preview-stock-modal', {
    on: {
      "submit": _vm.submitStock
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }